import React from "react"
import ArticleCover from "./ArticleCover"
import { graphql, useStaticQuery } from "gatsby"

export const articles = [
  // {
  //   title: 'Composition, Authorship, and Ownership in Flamenco, Past and Present',
  //   author: 'Peter Manuel',
  //   year: '2010',
  //   description: 'Among the most fundamental musical developments accompanying the advent of modernity has been the emergence of new conceptions of authorship, ownership, and the roles of composition. A growing body of literature has emerged which addresses various aspects of these processes in relation to diverse music cultures (e.g., Talbot 2000, Vaidhyanathan 2001, Frith 1993). In the realm of Western art music, scholarly attention has focused in particular on the celebration, especially from the Romantic period on, of the composer as an individual genius, and on the special importance of “the work,” as an original, reproducible, structurally unified, aesthetically unique, and privately owned entity (Goehr 1992).'
  // },
  {
    title: "Flamenco, regionalism and musical heritage in Southern Spain",
    author: "Bernat Jiménez de Cisneros Puig",
    year: "2017",
    description:
      " A great paradox has long surrounded flamenco: as an artistic expression, it is an exaltation of personality, of the emotional and intimate, and yet it has also been the object of political uses both at the regional and national levels, taken today as the emblem of Andalusian autonomy and the icon of the Marca España [Spanish Brand]. Despite the designation of flamenco as an Intangible Cultural Heritage of Humanity by UNESCO (2010), this ideological use has never been universally accepted in other Spanish regions or within Andalusia.",
  },
  {
    title:
      "Discovering Flamenco Metric Matrices through a Pulse-Level Analysis",
    author: "Bernat Jiménez de Cisneros Puig",
    year: "2017",
    description:
      "Until recent times, theoretical analyses devoted to flamenco music were scarce, and even these have tended to consist of publications that never obtained widespread distribution and recognition (Rossy 1966; Donnier 1987). Traditionally, classifications of flamenco repertoire have been based on different types of non-musical criteria, lacking a holistic musicological consideration and paying little or no attention to core concepts of flamenco such as metric formula, harmonic ostinato, melodic contour, poetic unfolding, or formal structure.  It is true that flamenco performers themselves had seldom used musical analysis in their own discourse—at least not in the way analysis is understood in Western music of written transmission.",
  },
  {
    title:
      "The Added Values of Recordings in Flamenco. On the Track of Tiento.",
    author: "Bernat Jiménez de Cisneros Puig",
    year: "2017",
    description:
      'Phonography approached flamenco from the very first moment, far before it became an industry: the first news of a flamenco recording dates back to 1880. Phonography has acted in flamenco not just as a complement for professional development, but also as a great spur to creativity, giving rise to new styles and genres that otherwise would have never existed. Certainly, flamenco phonography has been both a means to preserve and spread the repertoire, "de-locating" the learning process, and a fertile way to increase it. This paper first highlights the added values of recordings in flamenco, in contrast to its role in Western music, and the relevance acquired in the leading research of flamenco singing. Nevertheless, an updated compilation of the old flamenco phonography may also outline some important gaps in the digital re-releasing of this wealthy legacy. Finally, to illustrate its significance as a research tool, the origin of a flamenco genre known as Tiento is tracked, to show that, contrary to popular belief, it is in fact not a slow version of the genre today called Tango, but rather an evolution of Tanguillo de Cádiz.',
  },
  {
    title: "It Is not that Easy! The Cross-Meter Genres in Flamenco",
    author: "Bernat Jiménez de Cisneros Puig",
    year: "2018",
    description:
      'It is often said in Spain that Sevillana and Fandango de Huelva are music genres in triple time (3/4), but this is, at least, a half truth, such as describing a period of a year as a mere succession of days, without mentioning weeks or months. In 1966, the musicologist Hipólito Rossy put us on the trail of the real metric structure of Sevillana: "The most particular of sevillanas is the rhythmic combination. The singing is clearly in binary meter, while dancing is in ternary" (1998: 265). After Rossy, this combination has also been identified in Fandango de Huelva by Fernández (2004) and Hurtado (2009), as well as relating to Jota by Manzano (2007) and Castro (2014). At the same time, it has been one of the main issues of my musicological research on Flamenco, recently published as a digital audiobook (Jiménez de Cisneros 2015). In this article (originally presented as a lecture­recital), we will identify through embedded audio clips and graphic examples the binary and ternary elements displayed in Sevillana and Fandango de Huelva, the cross­meter genres of Flamenco, focusing on the guitar accompaniment and the melodic design. Through a pulse­level analysis, we will also look at the metric hierarchy between these elements, leading us to the cross­meter formula, which involves an alternative interpretation of compound meter. Finally, some characteristic hand clapping patterns will be included, so as the reader can approach these puzzling genres from the same point of view of the performer.',
  },
]

export default function ArticleList() {
  const { atril } = useStaticQuery(query)

  return (
    atril &&
    atril.books &&
    atril.books.filter(book => (book.es_libro === 0 ? book : null)).length >
      0 && (
      <div className="max-w-screen-xl mx-auto">
        <h2 className="relative text-2xl font-semibold mb-5 text-fire-500">
          Artículos{" "}
          <span className="absolute top-0 right-0 w-5 h-5 text-sm bg-gray-500 flex justify-center items-center text-white rounded-full">
            {
              atril.books?.filter(book => (book.es_libro === 1 ? book : null))
                .length
            }
          </span>
        </h2>
        <div className="grid grid-cols-2 gap-12 mb-16">
          {atril.books
            ?.filter(book => (book.es_libro === 0 ? book : null))
            .map(article => (
              <ArticleCover key={article.id} data={article} />
            ))}
        </div>
      </div>
    )
  )
}

const query = graphql`
  query {
    atril {
      books {
        id
        titulo
        slug
        subtitulo
        image
        color
        author
        createdAt
        es_libro
      }
    }
  }
`
