import React from 'react'
import _ from 'lodash'

export default function ArticleCover({ data: article }) {
  const { titulo, slug, author, createdAt, subtitulo } = article
  return (
    <div className="flex flex-col items-start mb-5 bg-gray-100 rounded p-3">
      <div className="relative flex items-center w-full space-x-5 overflow-hidden">
        {/* <Figure /> */}
        <div className="w-full">
          <h2 className="text-xl font-bold leading-tight rounded">
            {titulo}
          </h2>
          <h3 className="text-sm my-2 font-semibold text-gray-500">
            <a href="/">{author}</a> - {new Date(createdAt).getFullYear()}{/*  - 42 min */}
          </h3>
        </div>
      </div>
      <p className="leading-relaxed text-sm mb-5">
        {_.truncate( subtitulo, { 'length': 400, 'separator': /,? +/, 'omission': '...' }) }
      </p>
      <a href={`http://atril.clicksound.io:3000/r/${slug}`} className="px-3 py-1 text-sm border border-fire-500 text-fire-500 font-semibold hover:bg-fire-500 hover:text-white rounded cursor-pointer">Leer más</a>
    </div>
  )
}

// const Figure = () => (
//   <div className="p-3 w-24 bg-gray-100 rounded-sm" style={{ height: '6rem' }}>
//     <span className="block w-full h-1 bg-gray-200 rounded-sm mb-1" />
//     <span className="block w-10/12 h-1 bg-gray-200 rounded-sm mb-3" />
//     <span className="block w-11/12 h-1 bg-gray-200 rounded-sm mb-4" />
//     <span className="block w-10/12 h-1 bg-gray-200 rounded-sm mb-1" />
//     <span className="block w-full h-1 bg-gray-200 rounded-sm mb-1" />
//     <span className="block w-9/12 h-1 bg-gray-200 rounded-sm mb-1" />
//     <span className="block w-11/12 h-1 bg-gray-200 rounded-sm mb-3" />
//     <span className="block w-10/12 h-1 bg-gray-200 rounded-sm mb-1" />
//     <span className="block w-8/12 h-1 bg-gray-200 rounded-sm mb-1" />
//     <span className="block w-11/12 h-1 bg-gray-200 rounded-sm mb-1" />
//     <span className="block w-9/12 h-1 bg-gray-200 rounded-sm mb-1" />
//     <span className="block w-4/12 h-1 bg-gray-200 rounded-sm mb-1" />
//   </div>
// )