import React from "react"
import { graphql } from "gatsby"
import { Container } from "../components/lib/Container"
import BookList from "../components/BookList"
import ArticleList from "../components/ArticleList"
import CallToAction from "../components/sections/CallToActionSection"
import HeaderSection from "../components/lib/HeaderSection"
import Footer from "../components/lib/Footer"
import { micromark } from "micromark"
import { mappedNotionData } from "../utils/notionData"

export default function Lecturas({ data }) {
  const notionData = mappedNotionData(data.notion)

  return (
    <>
      <Container>
        <HeaderSection
          title="Lecturas"
          subtitle={notionData.title}
          text={micromark(notionData.body)}
        />
        <BookList />

        <ArticleList />
      </Container>
      <CallToAction />
      <Footer />
    </>
  )
}

export const query = graphql`
  query {
    notion(title: { eq: "recursos-header" }) {
      title
      properties {
        title {
          value
        }
        body {
          value
        }
      }
    }
  }
`
